














































import {Component, Prop, Vue } from 'vue-property-decorator';
import {OutputDeviceTypeDTO, UpdateDeviceTypeDTO} from "@/api";
import {AxiosError} from "axios";
import Loading from "@/components/Loading.vue";

@Component({
  components: {Loading}
})
export default class DeviceTypeEdit extends Vue {
  public deviceTypeName: string = '';
  public deviceTypeExternal_id: string|undefined = '';

  public deviceType: OutputDeviceTypeDTO|null = null;

  @Prop(Number) readonly deviceTypeId!: number;

  public async mounted() {
    this.deviceType = await this.$store.dispatch('deviceTypes/get', {deviceTypeId: this.deviceTypeId});
    this.reset();
  }

  public reset() {
    this.deviceTypeName = '';
    this.deviceTypeExternal_id = '';

    this.$validator.reset();
    if (this.deviceType) {
      this.deviceTypeName = this.deviceType.name
      this.deviceTypeExternal_id = this.deviceType.external_id
    }
  }

  public cancel() {
    this.$router.back();
  }

  // Todo: Not able to change only one of the fields
  public async submit() {
    if (this.deviceType === null) {
      return;
    }

    if (await this.$validator.validateAll()) {
      const dto: UpdateDeviceTypeDTO = {
        name: this.deviceTypeName,
        external_id: this.deviceTypeExternal_id!
      }

      try {
        await this.$store.dispatch('deviceTypes/update', {deviceTypeId: this.deviceType.id, data: dto})
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 400) {
          this.$store.commit('ui/addNotification', { content: 'Name or external ID is already in use.', color: 'error' })
          return;
        }
        throw error;
      }

      await this.$router.push('/main/admin/device-types');
    }
  }

  get loading() {
    return this.deviceType === null;
  }
}
